import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: 'GTM-MVNF2ZX',
  googleAnalyticsId: '',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: '5640165c-ff79-4312-9420-59678e3e40b6',
  footerLogoId: 'd99fb441-175f-4c3d-8e72-421a3cfb6d32',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&display=swap',
  defaultTitle: 'Jogue Jogos Grátis Online no SBT Games | SBT Games',
  titleTemplate: 'or-site-sbt | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/consent/e19ce778-7533-42b9-a6a0-b26a9d2891f6/otSDKStub.js',
      strategy: 'afterInteractive',
      'data-domain-script': 'e19ce778-7533-42b9-a6a0-b26a9d2891f6'
    },
    {
      id: 'ot-callback-script',
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      id: 'gtm-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', 'GTM-MVNF2ZX');
          `
      }
    },
    {
      src: 'https://pm.azerioncircle.com/p/focus/',
      strategy: 'afterInteractive',
    },
  ],
  links: [
  ],
};